import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { markChunkAsProcessed, fetchAudioForChunk } from '../../redux/slices/audioPlayerSlice';
import { AVATARS } from '../../redux/slices/avatarSlice';
import { setActiveAudio } from '../../redux/slices/avatarSlice';

const AudioPlayer = ({ }) => {
  const dispatch = useDispatch();
  const currentChunk = useSelector((state) => state.audioPlayer.currentChunk);
  const queue = useSelector((state) => state.audioPlayer.queue);
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const avatars = useSelector((state) => state.avatar.roles);

  useEffect(() => {
    if (currentChunk && !currentChunk.processed) {
      if (currentChunk.fetchedAudio) {
        playChunk(currentChunk);
      } else if (!currentChunk.fetchingAudio && !currentChunk.error) {
        dispatch(
          fetchAudioForChunk({
            chunkId: currentChunk.id,
            content: currentChunk.content,
            voice: AVATARS[avatars[currentChunk.conversationType]["currentAvatarIndex"]].voice,
          })
        );
      }
    }
  }, [currentChunk, currentChunk?.fetchedAudio]);

  useEffect(() => {
    if (queue.length > 0) {
      const nextChunk = queue[0];
      if (!nextChunk.fetchedAudio && !nextChunk.fetchingAudio && !nextChunk.error) {
        dispatch(
          fetchAudioForChunk({
            chunkId: nextChunk.id,
            content: nextChunk.content,
            voice: AVATARS[avatars[nextChunk.conversationType]["currentAvatarIndex"]].voice,
          })
        );
      }
    }
  }, [queue]);

  const playChunk = async (chunk) => {
    if (!chunk || chunk.processed) return;

    setIsPlaying(true);

    try {
      if (chunk.content.trim() !== '') {
        if (chunk.audioUrl) {
          audioRef.current.src = chunk.audioUrl;
          //await audioRef.current.play();
          audioRef.current.volume = 0;
          dispatch(
            setActiveAudio({
              role: chunk.conversationType,
              audioUrl: audioRef.current.src,
              audioDuration: audioRef.current.duration,
              text: '',
            })
          );
        } else {
          console.error('Audio URL not available for chunk:', chunk.id);
          handleAudioEnded();
        }
      } else {
        handleAudioEnded();
      }
    } catch (error) {
      console.error('Error playing audio:', error);
      handleAudioEnded();
    }
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
    if (currentChunk) {
      dispatch(markChunkAsProcessed(currentChunk.id));
    }
  };

  return (
    <div className="auto-audio-player">
      <audio ref={audioRef} onEnded={handleAudioEnded} />
    </div>
  );
};

export default AudioPlayer;