import loggerService from './loggerService';

const FLOWS_API_URL = process.env.REACT_APP_FLOWS_BASE_URL;

export async function* sendToFlowStream(message, sessionId, flowId) {
  try {
    // Verbose Level Log
    loggerService.verbose('Flow request details', {
      url: `${FLOWS_API_URL}/${flowId}`,
      method: 'POST',
      body: { input_value: message.substring(0, 100), session_id: sessionId },
    });

    const response = await fetch(`${FLOWS_API_URL}/${flowId}?stream=true`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          input_value: message,
          session_id: sessionId,
        }),
      });
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';
  
      while (true) {
        const { done, value } = await reader.read();
        
        if (done) {
          if (buffer.trim().length > 0) {
            loggerService.verbose('Processing remaining buffer on stream end', { buffer });
            yield* processJSONChunks(buffer);
          }
          break;
        }
        
        buffer += decoder.decode(value, { stream: true });
        yield* processJSONChunks(buffer);
        
        // Clear the buffer of processed chunks
        buffer = buffer.slice(buffer.lastIndexOf('}') + 1);
      }
    } catch (error) {
      loggerService.error('Error in sendToFlowStream', { error: error.message });
      throw error;
    }
  }
  
  function* processJSONChunks(buffer) {
    const chunks = buffer.match(/\{[^{}]*\}/g) || [];
    for (const chunk of chunks) {
      try {
        const parsedChunk = JSON.parse(chunk);
        loggerService.verbose('Parsed chunk', { parsedChunk });
        yield JSON.stringify(parsedChunk);
      } catch (error) {
        loggerService.error('Error parsing chunk', { chunk, error: error.message });
      }
    }
  }

export async function sendToFlowNoStream(message, sessionId, flowId) {
  try {
    // Verbose Level Log
    loggerService.verbose('Flow request details', {
      url: `${FLOWS_API_URL}/${flowId}`,
      method: 'POST',
      body: { input_value: message.substring(0, 100), session_id: sessionId },
    });

    const startTime = new Date();
    const response = await fetch(`${FLOWS_API_URL}/${flowId}?stream=false`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        input_value: message,
        session_id: sessionId,
      }),
    });

    const duration = new Date() - startTime;
    loggerService.verbose('Flow response time', { duration: `${duration}ms` });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    loggerService.verbose('Received response from flow (' + flowId + ')', { responseData: data });

    return data;
  } catch (error) {
    loggerService.error('Error sending message to flow no-stream', {
      flowId,
      error: error.message,
    });
    loggerService.verbose('No-stream error details', { error });
    throw error;
  }
}