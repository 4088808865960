import React from 'react';
import MessageList from './messageList/MessageList';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '../common/avatar/Avatar';
import { handleLevelUpgrade } from '../../services/levelService';
import { selectSessionId } from '../../redux/slices/sessionSlice';
import UserVoiceMessage from '../common/userVoiceMessage/UserVoiceMessage';
import './ChatPanel.scss';


const ChatPanel = () => {

  const instructorRole = process.env.REACT_APP_AVATAR_ROLE_INSTRUCTOR;

  const dispatch = useDispatch();
  const sessionId = useSelector(selectSessionId);
  const programFlowId = useSelector(
    (state) => state.program.data?.program_flow_id
  );
  const userAdvancementEvaluatorFlowId = useSelector(
    (state) => state.program.data?.user_advancement_evaluator_flow_id
  );

  const handleFinalTranscript = (finalText) => {
    handleLevelUpgrade(finalText, sessionId, programFlowId, userAdvancementEvaluatorFlowId, instructorRole, dispatch);
  };

  return (
    <div className="chat-panel">

      <div className="chat-panel__header">
        <div className="chat-panel__wrapper">
          <h2 className="chat-panel__title">Instructor Chat</h2>
        </div>
      </div>

      <div className="chat-panel__body">
        <div className="chat-panel__avatar">
          <Avatar role={instructorRole}/>
        </div>

        <div className="chat-panel__message-list">
          <MessageList />
        </div>
      </div>

      <div className="chat-panel__footer">
        <UserVoiceMessage onFinalTranscript={handleFinalTranscript} />
      </div>
    </div>
  );
};

export default ChatPanel;
