import { createSlice } from '@reduxjs/toolkit';
import loggerService from '../../services/loggerService';

const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    sessionId: null,
  },
  reducers: {
    setSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
  },
});

export const { setSessionId } = sessionSlice.actions;

export const selectSessionId = (state) => state.session.sessionId;

export default sessionSlice.reducer;