import { configureStore } from '@reduxjs/toolkit';
import sessionReducer from './slices/sessionSlice';
import programReducer from './slices/programSlice';
import currentLevelReducer from './slices/currentLevelSlice';
import chatReducer from './slices/chatSlice';
import avatarReducer from './slices/avatarSlice';
import audioPlayerReducer from './slices/audioPlayerSlice';

const store = configureStore({
  reducer: {
    session: sessionReducer,
    program: programReducer,
    currentLevel: currentLevelReducer,
    chat: chatReducer,
    avatar: avatarReducer,
    audioPlayer: audioPlayerReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;