import React, { useState, useEffect } from 'react';
import Button from '../button/Button';

import './UserVoiceMessage.scss';

const UserVoiceMessage = ({ onFinalTranscript }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [interimTranscript, setInterimTranscript] = useState('');

  useEffect(() => {
    let recognition;

    if (!('webkitSpeechRecognition' in window)) {
      console.error('Web Speech API is not supported by this browser.');
      return;
    }

    recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;  // Keep recognizing speech until manually stopped
    recognition.interimResults = true;  // Receive partial results as the user speaks
    recognition.lang = 'en-US';  // Language set to English

    recognition.onresult = (event) => {
      let interimText = '';
      let finalText = '';


      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalText += event.results[i][0].transcript;
          setIsRecording(false);
          onFinalTranscript(finalText);
          recognition.stop();
        } else {
          interimText += event.results[i][0].transcript;
        }
      }

      setInterimTranscript(interimText);
    };

    recognition.onerror = (event) => {
      console.error('Speech recognition error:', event.error);
    };

    if (isRecording) {
      recognition.start();
    } else {
      recognition.stop();
    }

    return () => {
      if (recognition) {
        //recognition.stop();
      }
    };
  }, [isRecording]);

  return (
    <div className="voice-message">
      {!isRecording && (
        <div className="voice-message__btn">
          <Button secondary onClick={() => setIsRecording(!isRecording)}></Button>
        </div>
      ) || (
        <div className="voice-message__input">
          {
            interimTranscript && (
              <p>{interimTranscript}</p>
            ) || <p className="voice-message__typing-dots"></p>
          }
        </div>
      )}
    </div>
  );
};

export default UserVoiceMessage;
