import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import mermaid from "mermaid";
import panzoom from "panzoom";
import { selectSessionId } from '../../../redux/slices/sessionSlice';
import { sendToFlowNoStream } from '../../../services/flowsAPIService';
import Avatar from '../../common/avatar/Avatar';
import UserVoiceMessage from '../../common/userVoiceMessage/UserVoiceMessage';
import { sendMessage } from '../../../redux/slices/chatSlice';
import loggerService from '../../../services/loggerService';

import './Brainstorm.scss';

const Brainstorm = () => {
    const mermaidRef = useRef(null);
    const panzoomInstance = useRef(null);
    const [chart, setChart] = useState('');
    const sessionId = useSelector(selectSessionId);
    const currentLevel = useSelector((state) => state.currentLevel.data);

    const brainstormAssistantRole = process.env.REACT_APP_AVATAR_ROLE_BRAINSTORM_ASSISTANT;

    const dispatch = useDispatch();
    const isTTSEnabled = useRef(new URLSearchParams(window.location.search).get('tts') !== 'false');

    useEffect(() => {
        mermaid.initialize({
            startOnLoad: false,
            theme: 'base',
            mindmap: {
                padding: 10,
                useMaxWidth: true,
            }
        });
        updateMindmap();
    }, [chart]);

    const sendMessageAndUpdateChart = async (content) => {
        dispatch(
            sendMessage({
                content,
                stream: true,
                sessionId,
                flowId: currentLevel.assistant_flow.external_flow_id,
                conversationType: brainstormAssistantRole
            })
        );
        
        try {
            const response = await sendToFlowNoStream(content, sessionId, currentLevel.idea_extractor_flow.external_flow_id);
            setChart(response.content);
            loggerService.info("chart is set, now it's: " + response.content);
        } catch (error) {
            console.error('Error sending to flow:', error);
            loggerService.error('Failed to set chart: ' + error.message);
        }
    };
    
    useEffect(() => {
        console.log("BRAINSTORM IS MOUNTED")
        sendMessageAndUpdateChart('start_the_level');
    }, []);

    const updateMindmap = async () => {
        if (!mermaidRef.current || !chart) return;
        loggerService.info("updating mindmap: " + chart);
        try {
            const { svg } = await mermaid.render('mindmap', chart);
            mermaidRef.current.innerHTML = svg;
            const svgElement = mermaidRef.current.querySelector('svg');
            if (svgElement) {
                svgElement.style.maxHeight = '100%';
                svgElement.style.width = 'auto';
            }
            
            // Initialize panzoom after rendering the SVG
            if (panzoomInstance.current) {
                panzoomInstance.current.dispose();
            }
            panzoomInstance.current = panzoom(svgElement, {
                maxZoom: 5,
                minZoom: 0.5,
                bounds: true,
                boundsPadding: 0.1
            });
        } catch (error) {
            console.error('Mermaid rendering failed:', error);
        }
    };

    const handleFinalTranscript = async (finalText) => {
        await sendMessageAndUpdateChart(finalText);
    };

    return (
        <div className="brainstorm">
            <div className="brainstorm__avatar">
                <Avatar role={brainstormAssistantRole} />
            </div>

            <div className="brainstorm__mindmap">
                <div ref={mermaidRef} />
            </div>
            <UserVoiceMessage onFinalTranscript={handleFinalTranscript} />
        </div>
    );
};

export default Brainstorm;