import { createSlice, createAsyncThunk, nanoid } from '@reduxjs/toolkit';
import { getTTSAudioForChunk } from '../../services/studioAPIService';

// Add a logging function with namespace
const log = (message, data) => {
  console.log(`[AudioPlayer] ${message}`, data);
};

export const fetchAudioForChunk = createAsyncThunk(
  'audioPlayer/fetchAudioForChunk',
  async ({ chunkId, content, voice }, thunkAPI) => {
    const audioBlob = await getTTSAudioForChunk(content, voice);
    const audioUrl = URL.createObjectURL(audioBlob);
    return { chunkId, audioUrl };
  }
);

const initialState = {
  isPlaying: false,
  currentChunkId: null,
  currentChunk: null,
  queue: [],
};

const audioPlayerSlice = createSlice({
  name: 'audioPlayer',
  initialState,
  reducers: {
    play: (state) => {
      state.isPlaying = true;
      log('Playback started', { currentChunkId: state.currentChunkId });
    },
    pause: (state) => {
      state.isPlaying = false;
      log('Playback paused', { currentChunkId: state.currentChunkId });
    },
    setCurrentChunk: (state, action) => {
      const chunk = { ...action.payload, id: nanoid() };
      state.currentChunkId = chunk.id;
      state.currentChunk = chunk;
      log('Current chunk set', {
        id: chunk.id,
        content: chunk.content,
        conversationType: chunk.conversationType
      });
    },
    clearCurrentChunk: (state) => {
      log('Current chunk cleared', { previousChunkId: state.currentChunkId });
      state.currentChunkId = null;
      state.currentChunk = null;
      state.isPlaying = false;
    },
    addToQueue: {
      reducer: (state, action) => {
        if (!action.payload.content || action.payload.content.trim() === '') {
          return; // Don't add empty chunks to the queue
        }
        if (state.currentChunk === null && state.queue.length === 0) {
          state.currentChunkId = action.payload.id;
          state.currentChunk = action.payload;
          log('Chunk added as current', {
            id: action.payload.id,
            content: action.payload.content,
            conversationType: action.payload.conversationType
          });
        } else {
          state.queue.push(action.payload);
          log('Chunk added to queue', {
            id: action.payload.id,
            content: action.payload.content,
            conversationType: action.payload.conversationType
          });
        }
      },
      prepare: (chunk) => ({
        payload: {
          ...chunk,
          id: nanoid(),
          processed: false,
          audioUrl: null,
          fetchingAudio: false,
          fetchedAudio: false,
          error: null,
        },
      }),
    },
    removeFromQueue: (state, action) => {
      log('Removing chunk from queue', { chunkId: action.payload });
      state.queue = state.queue.filter(chunk => chunk.id !== action.payload);
    },
    clearQueue: (state) => {
      log('Queue cleared', { queueLength: state.queue.length });
      state.queue = [];
    },
    playNext: (state) => {
      const nextChunk = state.queue.shift();
      if (nextChunk) {
        state.currentChunkId = nextChunk.id;
        state.currentChunk = nextChunk;
        state.isPlaying = true;
        log('Playing next chunk', {
          id: nextChunk.id,
          content: nextChunk.content,
          conversationType: nextChunk.conversationType
        });
      } else {
        state.isPlaying = false;
        state.currentChunkId = null;
        state.currentChunk = null;
        log('Queue is empty, playback stopped');
      }
    },
    markChunkAsProcessed: (state, action) => {
      if (state.currentChunk && state.currentChunk.id === action.payload) {
        state.currentChunk.processed = true;
        log('Current chunk marked as processed', {
          id: state.currentChunk.id,
          content: state.currentChunk.content,
          conversationType: state.currentChunk.conversationType
        });
        
        const nextChunk = state.queue.shift();
        if (nextChunk) {
          state.currentChunkId = nextChunk.id;
          state.currentChunk = nextChunk;
          state.isPlaying = true;
          log('Moving to next chunk', {
            id: nextChunk.id,
            content: nextChunk.content,
            conversationType: nextChunk.conversationType
          });
        } else {
          state.currentChunkId = null;
          state.currentChunk = null;
          state.isPlaying = false;
          log('No more chunks in queue, playback finished');
        }
      }
      state.queue = state.queue.map(chunk => 
        chunk.id === action.payload ? { ...chunk, processed: true } : chunk
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAudioForChunk.pending, (state, action) => {
        const chunkId = action.meta.arg.chunkId;
        const chunk = state.queue.find((c) => c.id === chunkId) || (state.currentChunk && state.currentChunk.id === chunkId ? state.currentChunk : null);
        if (chunk) {
          chunk.fetchingAudio = true;
          log('Fetching audio for chunk', {
            id: chunk.id,
            content: chunk.content,
            conversationType: chunk.conversationType
          });
        }
      })
      .addCase(fetchAudioForChunk.fulfilled, (state, action) => {
        const { chunkId, audioUrl } = action.payload;
        const chunk = state.queue.find((c) => c.id === chunkId) || (state.currentChunk && state.currentChunk.id === chunkId ? state.currentChunk : null);
        if (chunk) {
          chunk.fetchingAudio = false;
          chunk.fetchedAudio = true;
          chunk.audioUrl = audioUrl;
          log('Audio fetched successfully for chunk', {
            id: chunk.id,
            content: chunk.content,
            conversationType: chunk.conversationType
          });
        }
      })
      .addCase(fetchAudioForChunk.rejected, (state, action) => {
        const chunkId = action.meta.arg.chunkId;
        const chunk = state.queue.find((c) => c.id === chunkId) || (state.currentChunk && state.currentChunk.id === chunkId ? state.currentChunk : null);
        if (chunk) {
          chunk.fetchingAudio = false;
          chunk.error = action.error.message;
          log('Error fetching audio for chunk', {
            id: chunk.id,
            content: chunk.content,
            conversationType: chunk.conversationType,
            error: action.error.message
          });
        }
      });
  },
});

export const {
  play,
  pause,
  setCurrentChunk,
  clearCurrentChunk,
  addToQueue,
  removeFromQueue,
  clearQueue,
  playNext,
  markChunkAsProcessed,
} = audioPlayerSlice.actions;

export default audioPlayerSlice.reducer;