import axios from 'axios';
import loggerService from './loggerService';

const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

const graphqlService = {
  query: async (query, variables) => {
    try {
      const response = await axios.post(graphqlEndpoint, {
        query,
        variables,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return response.data;
    } catch (error) {
      loggerService.error('GraphQL Error', error.response ? error.response.data : error.message);
      if (error.response && error.response.status === 401) {
        window.location.href = process.env.REACT_APP_TRAINING_STUDIO_URL;
      }
      throw error;
    }
  },
};

export default graphqlService;