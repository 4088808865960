import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Message from '../message/Message';
import loggerService from '../../../services/loggerService';

import './MessageList.scss';

const MessageList = () => {
  const instructorRole = process.env.REACT_APP_AVATAR_ROLE_INSTRUCTOR;
  const instructoMessages = useSelector(state => state.chat.messages.filter(message => message.conversationType === 'instructor'));

  const scrollRef = useRef();



  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [instructoMessages]);

  const shouldDisplayMessage = (message) => {
    return message.content !== "start_the_course" && message.content !== "start_the_level";
  };

  loggerService.verbose('Rendering message list', { messageCount: instructoMessages.length });

  return (
    <div className="message-list" ref={scrollRef}>
      {instructoMessages.filter(shouldDisplayMessage).map(message => (
        <React.Fragment key={message.id}>
          <Message
            messageId={message.id}
            isUser={message.isUser}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default MessageList;
