import axios from 'axios';
import Cookies from 'js-cookie';
import loggerService from './loggerService';

const API_URL = process.env.REACT_APP_API_BASE_URL;
const TTS_ENDPOINT = process.env.REACT_APP_TTS_ENDPOINT || '/audio/tts';
const DEFAULT_VOICE = process.env.REACT_APP_DEFAULT_VOICE || 'shimmer';

const studioAPI = axios.create({
  baseURL: API_URL,
  withCredentials: true
});

studioAPI.interceptors.request.use(
  (config) => {
    const token = Cookies.get('auth_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    config.metadata = { startTime: new Date() };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

studioAPI.interceptors.response.use(
  (response) => {
    const duration = new Date() - response.config.metadata.startTime;
    return response;
  },
  (error) => {
    const duration = new Date() - error.config.metadata.startTime;
    return Promise.reject(error);
  }
);

export const sendToFlow = async (message, stream, sessionId, flowId) => {
  try {
    loggerService.verbose('Send to flow request details', { message, stream, sessionId, flowId });
    const response = await studioAPI.post('/chat/send_to_flow', {
      text: message,
      sessionId: sessionId,
      flowId: flowId,
      stream: stream
    });
    loggerService.verbose('Flow response details', response.data);
    return response.data;
  } catch (error) {
    loggerService.error('Send to flow error details', error);
    throw error;
  }
};

export const getTTSAudioForChunk = async (text, voice = DEFAULT_VOICE) => {
  try {
    loggerService.verbose('TTS request details', { text, voice });
    const response = await studioAPI.post(TTS_ENDPOINT, 
      { voice, text },
      { responseType: 'blob' }
    );
    loggerService.verbose('TTS response details', { size: response.data.size });
    return response.data;
  } catch (error) {
    loggerService.error('TTS error details', error);
    throw error;
  }
};

export default studioAPI;