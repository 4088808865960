import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import graphqlService from '../../services/graphqlService';
import loggerService from '../../services/loggerService';
import { resetCurrentLevelAssistantMessageCount } from './chatSlice';

// Fetch current level and include activity_type
export const fetchCurrentLevel = createAsyncThunk(
  'currentLevel/fetchCurrentLevel',
  async (sessionId, thunkAPI) => {
    const query = `
      query ($where: SessionsResolver_Session_FilterInputType) {
        session(where: $where) {
          current_level {
            id
            index
            description_short
            activity_type
            activity {
              ... on RoleplayActivityObject {
                partner_flow {
                  external_flow_id
                }
                observer_flow {
                  external_flow_id
                }
                legend
              }
              ... on BrainstormActivityObject {
                idea_extractor_flow {
                  external_flow_id
                }
                assistant_flow {
                  external_flow_id
                }
              }
            }
          }
        }
      }
    `;

    const variables = {
      where: { id: { eq: sessionId } },
    };

    loggerService.verbose('Current level GraphQL query', { query, variables });

    const response = await graphqlService.query(query, variables);

    // Info Level Log
    loggerService.info('Current level fetched', {
      levelIndex: response.data.session.current_level.index,
      levelDescription: response.data.session.current_level.description_short.replace(/\s+/g, ' ').substring(0, 50),
    });

    loggerService.verbose('Current level data received', {
      levelData: response.data.session.current_level,
    });

    thunkAPI.dispatch(resetCurrentLevelAssistantMessageCount());

    return response.data.session.current_level;
  }
);

const currentLevelSlice = createSlice({
  name: 'currentLevel',
  initialState: {
    data: null,
    status: 'idle',
    error: null,
    currentLevelType: null,  // Track the current level type (roleplay, brainstorm, etc.)
  },
  reducers: {
    setCurrentLevelType: (state, action) => {
      state.currentLevelType = action.payload; // Set current level type manually if needed
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentLevel.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCurrentLevel.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = {
          id: action.payload.id,
          index: action.payload.index,
          description_short: action.payload.description_short,
          activity_type: action.payload.activity_type,
          ...action.payload.activity[0] // Spread the first (and only) activity object
        };

        // Automatically set currentLevelType from activity_type
        state.currentLevelType = action.payload.activity_type;
      })
      .addCase(fetchCurrentLevel.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        loggerService.error('fetchCurrentLevel rejected', action.error);
      });
  }
});

// Exporting the reducer and selectors
export const { setCurrentLevelType } = currentLevelSlice.actions;

export const selectCurrentLevelStatus = (state) => state.currentLevel.status;
export const selectCurrentLevelData = (state) => state.currentLevel.data;
export const selectCurrentLevelType = (state) => state.currentLevel.currentLevelType;  // Selector for currentLevelType

export default currentLevelSlice.reducer;