import React from 'react';

import './Button.scss';

const BUTTON_TYPES = {
  primary: 'primary',
  secondary: 'secondary',
  text: 'text',
};

const Button = ({ onClick, children, ...rest}) => {
  const typeClass = Object.keys(BUTTON_TYPES).find(key => rest[key]) ? `ts-button--${Object.keys(BUTTON_TYPES).find(key => rest[key])}` : '';
  return (
      <button onClick={onClick}
              className={`ts-button ts-button--default ts-button--dark ts-button--only-icon ${typeClass}`} type="submit">
        <span className="ts-button__icon">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24">
            <path fill="currentColor" d="M8.05 6.45A4.449 4.449 0 0 1 12.5 2a4.449 4.449 0 0 1 4.45 4.45v5.087a4.449 4.449 0 0 1-4.45 4.45 4.449 4.449 0 0 1-4.45-4.45V6.45ZM12.5 3.5c-1.63 0-2.95 1.32-2.95 2.95v5.087a2.949 2.949 0 1 0 5.9 0V6.45c0-1.63-1.32-2.95-2.95-2.95ZM5.424 9.076a.75.75 0 0 1 .75.75V11.4a6.332 6.332 0 0 0 6.326 6.326 6.332 6.332 0 0 0 6.326-6.326V9.826a.75.75 0 0 1 1.5 0V11.4c0 4.065-3.11 7.412-7.076 7.79v2.061a.75.75 0 0 1-1.5 0v-2.06c-3.967-.379-7.076-3.727-7.076-7.791V9.826a.75.75 0 0 1 .75-.75Z"/>
          </svg>
        </span>
      </button>
  );
};

export default Button;
