import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { sendToFlowStream, sendToFlowNoStream } from '../../services/flowsAPIService';
import loggerService from '../../services/loggerService';
import { setCurrentMessageToTTS } from './audioPlayerSlice';
import { addToQueue } from './audioPlayerSlice';

export const sendMessage = createAsyncThunk(
  'chat/sendMessage',
  async ({ content, stream = false, sessionId, flowId, conversationType }, { dispatch, getState }) => {
    loggerService.verbose('Sending message to flow', {
      messageContent: content.substring(0, 100),
      sessionId,
      flowId,
      stream,
    });

    console.log(">>>>>>>>>>> convtype: " + conversationType)

    const currentState = getState();
    const currentLevelIndex = currentState.currentLevel.data?.index ?? null;
    const currentLevelActivityType = currentState.currentLevel.data?.activity_type ?? null;
    const currentLevelAssistantMessageCount = currentState.chat.currentLevelAssistantMessageCount;

    const userMessage = {
      id: uuidv4(),
      content,
      chunks: [{ content, isProcessed: false, index: 0 }],
      isUser: true,
      currentLevelIndex,
      currentLevelActivityType,
      conversationType,
    };

    dispatch(addMessage(userMessage));

    if (stream) {
      const assistantMessageId = uuidv4();
      dispatch(
        addMessage({
          id: assistantMessageId,
          content: '',
          chunks: [],
          isUser: false,
          currentLevelIndex,
          currentLevelActivityType,
          currentLevelAssistantMessageCount,
          conversationType,
        })
      );

      let chunkIndex = 0;
      let assistantMessageContent = '';

      
      //dispatch(setCurrentMessageToTTS(assistantMessageId));

      for await (const chunk of sendToFlowStream(content, sessionId, flowId)) {
        const parsedChunk = JSON.parse(chunk);
        const newChunk = {
          content: parsedChunk.content,
          isProcessed: false,
          isLast: parsedChunk.finished,
          index: chunkIndex++,
        };
        dispatch(addChunk({ messageId: assistantMessageId, chunk: newChunk }));
        dispatch(addToQueue({
          content: parsedChunk.content,
          conversationType,
        }));
        assistantMessageContent += parsedChunk.content;
        if (parsedChunk.finished) break;
      }

      if (conversationType === process.env.REACT_APP_AVATAR_ROLE_INSTRUCTOR) {
        dispatch(incrementCurrentLevelAssistantMessageCount());
      }

      loggerService.info(
        `Message sent to flow and response received: "${assistantMessageContent
          .replace(/\s+/g, ' ')
          .trim()
        }"`
      );
    } else {
      const response = await sendToFlowNoStream(content, sessionId, flowId);
      const assistantMessage = {
        id: uuidv4(),
        content: response.content,
        chunks: [{ content: response.content, isProcessed: false, index: 0 }],
        isUser: false,
        currentLevelIndex,
        currentLevelActivityType,
        currentLevelAssistantMessageCount,
        conversationType,
      };
      dispatch(addMessage(assistantMessage));
      if (conversationType === process.env.REACT_APP_AVATAR_ROLE_INSTRUCTOR) {
        dispatch(incrementCurrentLevelAssistantMessageCount());
      }

      loggerService.info(
        `Message sent to flow and response received: "${response.content
          .replace(/\s+/g, ' ')
          .trim()
          .substring(0, 50)}"`
      );
    }
  }
);

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    messages: [],
    isLoading: false,
    currentLevelAssistantMessageCount: 0,
  },
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    addChunk: (state, action) => {
      const { messageId, chunk } = action.payload;
      const message = state.messages.find((msg) => msg.id === messageId);
      if (message) {
        message.chunks.push(chunk);
        message.content = message.chunks.map((c) => c.content).join('');
      }
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    markChunkProcessed: (state, action) => {
      const { messageId, chunkIndex } = action.payload;
      const message = state.messages.find((msg) => msg.id === messageId);
      if (message) {
        const chunk = message.chunks.find((c) => c.index === chunkIndex);
        if (chunk) {
          chunk.isProcessed = true;
        }
      }
    },
    incrementCurrentLevelAssistantMessageCount: (state) => {
      state.currentLevelAssistantMessageCount += 1;
    },
    resetCurrentLevelAssistantMessageCount: (state) => {
      state.currentLevelAssistantMessageCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendMessage.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(sendMessage.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { 
  addMessage, 
  addChunk, 
  setLoading, 
  markChunkProcessed, 
  incrementCurrentLevelAssistantMessageCount, 
  resetCurrentLevelAssistantMessageCount 
} = chatSlice.actions;

export default chatSlice.reducer;