import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import graphqlService from '../../services/graphqlService';
import loggerService from '../../services/loggerService';

export const fetchProgramInfo = createAsyncThunk(
  'program/fetchProgramInfo',
  async (sessionId) => {
    const query = `
      query ($where: SessionsResolver_Session_FilterInputType) {
        session(where: $where) {
          program {
            id
            title
            program_flow {
              external_flow_id
            }
            user_advancement_evaluator_flow {
              external_flow_id
            }
          }
        }
      }
    `;

    const variables = {
      where: { id: { eq: sessionId } },
    };

    loggerService.verbose('Program info GraphQL query', { query, variables });

    const response = await graphqlService.query(query, variables);

    // Info Level Log
    loggerService.info('Program info fetched', {
      programTitle: response.data.session.program.title,
    });

    loggerService.verbose('Program data received', {
      programData: response.data.session.program,
    });

    return response.data.session.program;
  }
);

const programSlice = createSlice({
  name: 'program',
  initialState: {
    data: null,
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProgramInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProgramInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Flatten the structure
        state.data = {
          id: action.payload.id,
          title: action.payload.title,
          program_flow_id: action.payload.program_flow.external_flow_id,
          user_advancement_evaluator_flow_id: action.payload.user_advancement_evaluator_flow.external_flow_id
        };
      })
      .addCase(fetchProgramInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        loggerService.error('fetchProgramInfo rejected', action.error);
      });
  }
});

export const selectProgramStatus = (state) => state.program.status;
export const selectProgramData = (state) => state.program.data;

export default programSlice.reducer;