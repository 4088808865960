import React, {useRef, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectSessionId } from '../../../redux/slices/sessionSlice';
import Avatar from '../../common/avatar/Avatar';

import './Roleplay.scss';

import UserVoiceMessage from '../../common/userVoiceMessage/UserVoiceMessage';
import { sendMessage } from '../../../redux/slices/chatSlice';

const Roleplay = () => {

    const roleplayPartnerRole = process.env.REACT_APP_AVATAR_ROLE_ROLEPLAY_PARTNER;

    const {legend, setLegend} = useState("");
    /* data: {
        id: 'b50efdf0-3619-4f52-a999-a2d574abacf6',
        index: 2,
        description_short: 'Tech support roleplay',
        activity_type: 'roleplay',
        partner_flow: {
          external_flow_id: 'roleplay_assistant_flow'
        },
        observer_flow: {
          external_flow_id: 'roleplay_observer_flow'
        } */
          const dispatch = useDispatch();
       const currentLevel = useSelector((state) => state.currentLevel.data);
       const sessionId = useSelector(selectSessionId);

       const isTTSEnabled = useRef(new URLSearchParams(window.location.search).get('tts') !== 'false');
       const handleFinalTranscript = (finalText) => {
           dispatch(
               sendMessage({
                   content: finalText,
                   stream: true,
                   sessionId,
                   flowId: currentLevel.partner_flow.external_flow_id,
                   conversationType: roleplayPartnerRole
               })
               );
/*                dispatch(
                   sendMessage({
                       content: finalText,
                       stream: false,
                       sessionId,
                       flowId: currentLevel.idea_extractor_flow.external_flow_id,
                       conversationType: roleplayPartnerRole
                   })
                   ); */
              }
  return (
    <div className="roleplay">
      <div className="roleplay__avatar">
        <Avatar role={roleplayPartnerRole}/>
      </div>

      <p className="roleplay__legend" dangerouslySetInnerHTML={{ __html: currentLevel.legend }} />

      <div className="roleplay__footer">
        <UserVoiceMessage onFinalTranscript={handleFinalTranscript} />
      </div>
    </div>
  );
};

export default Roleplay;
