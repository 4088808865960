import { sendToFlowNoStream } from './flowsAPIService';
import graphqlService from './graphqlService';
import { fetchCurrentLevel } from '../redux/slices/currentLevelSlice';
import { sendMessage } from '../redux/slices/chatSlice';
import loggerService from './loggerService';

export const handleLevelUpgrade = async (
  message,
  sessionId,
  programFlowId,
  userAdvancementEvaluatorFlowId,
  conversationType,
  dispatch
) => {
  try {
    // Verbose Level Log
    loggerService.verbose('handleLevelUpgrade details', {
      message: message.substring(0, 100),
      sessionId,
      programFlowId,
      userAdvancementEvaluatorFlowId,
    });

    const isUserReadyResponse = await sendToFlowNoStream(
      message,
      sessionId,
      userAdvancementEvaluatorFlowId
    );
    loggerService.verbose('User readiness response raw: ', { isUserReadyResponse });
    const isUserReady = isUserReadyResponse.content === "true";

    loggerService.verbose('User readiness response: ', { isUserReady });

    if (isUserReady) {
      const mutation = `
        mutation IncrementCurrentLevel($input: IncrementCurrentLevelDto!) {
          incrementCurrentLevel(input: $input) {
            id
          }
        }
      `;
      const variables = {
        input: { session_id: sessionId },
      };

      loggerService.verbose('Increment level GraphQL mutation', {
        mutation,
        variables,
      });

      const mutationResponse = await graphqlService.query(mutation, variables);
      loggerService.verbose('Increment level mutation response', {
        response: mutationResponse,
      });

      const currentLevelData = await dispatch(fetchCurrentLevel(sessionId)).unwrap();

      // Info Level Log
      loggerService.info('User advanced to the next level', {
        levelIndex: currentLevelData.index,
        levelDescription: currentLevelData.description_short.replace(/\s+/g, ' ').substring(0, 50),
      });

      loggerService.verbose('Fetching updated current level', { sessionId });

    }

    loggerService.verbose('Sending message to level manager', {
      message: message.substring(0, 100),
    });

    dispatch(
      sendMessage({
        content: message,
        stream: true,
        sessionId,
        flowId: programFlowId,
        conversationType
      })
    );
  } catch (error) {
    loggerService.error('Error during level upgrade', { error: error.message });
    loggerService.verbose('handleLevelUpgrade error details', { error });
  }
};

export const handleLevelReset = async (sessionId) => {
  try {
    loggerService.verbose('Initiating level reset', { sessionId });

    const mutation = `
      mutation ResetCurrentLevel($input: IncrementCurrentLevelDto!) {
        resetCurrentLevel(input: $input) {
          id
        }
      }
    `;
    const variables = { input: { session_id: sessionId } };

    loggerService.verbose('Level reset GraphQL mutation', { mutation, variables });

    const mutationResponse = await graphqlService.query(mutation, variables);
    loggerService.verbose('Level reset mutation response', {
      response: mutationResponse,
    });

    const currentLevelData = await graphqlService.query(
      `
      query ($where: SessionsResolver_Session_FilterInputType) {
        session(where: $where) {
          current_level {
            id
            index
            description_short
          }
        }
      }
    `,
      { where: { id: { eq: sessionId } } }
    );

    const levelData = currentLevelData.data.session.current_level;

    // Info Level Log
    loggerService.info('Level reset successful', {
      levelIndex: levelData.index,
      levelDescription: levelData.description_short.replace(/\s+/g, ' ').substring(0, 50),
    });

    return levelData;
  } catch (error) {
    loggerService.error('Error resetting level', { error: error.message });
    loggerService.verbose('Level reset error details', { error });
    throw error;
  }
};