import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './redux/store';
import ReactDOM from 'react-dom/client';
import App from './App';

import './styles/styles.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <Provider store={store}>
      <App />
    </Provider>
);
