import React from 'react';

import './Header.scss';

const Header = ({ title }) => {
    return (
        <div className="header">
          <div className="header__info">
            <h1 className="header__title">{ title }</h1>
          </div>
        </div>
    );
};

export default Header;
