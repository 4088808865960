import React, {useEffect} from 'react';
import Roleplay from './roleplay/Roleplay';
import Brainstorm from './brainstorm/Brainstorm';

import './ActivityPanel.scss';
import {useSelector} from 'react-redux';

const ActivityPanel = ({ onClose, activityType }) => {

  // Function to render the appropriate activity component based on the activityType
  const renderActivity = () => {
    switch (activityType) {
      case 'roleplay':
        return <Roleplay />;
      case 'brainstorm':
        return <Brainstorm />;
      default:
        return <p>No activity selected</p>;
    }
  };

  useEffect(() => {
    console.log("ACTIVITY PANEL IS MOUNTED")
}, []);

  const currentLevel = useSelector((state) => {
    const type = state.currentLevel.data.activity_type || '';
    let activity_type = '';

    if (type.length > 0) {
      activity_type = `${type[0].toUpperCase()}${type.slice(1)}`;
    }

    return {
      activity_type,
      description_short: state.currentLevel.data.description_short
    }
  });

  return (
    <div className="activity-panel">
      <div className="activity-panel__header">
        <div className="activity-panel__wrapper">
          <h2 className="activity-panel__title">{ currentLevel.activity_type }: { currentLevel.description_short }</h2>
        </div>
      </div>

      <div className="activity-panel__container">
        { renderActivity() } {/* Conditionally render the activity */}
      </div>
    </div>
  );
};

export default ActivityPanel;
