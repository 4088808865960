import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Unity, useUnityContext } from "react-unity-webgl";
import { rotateAvatar, AVATARS } from '../../../redux/slices/avatarSlice';

import './Avatar.scss';

const Avatar = ({ role }) => {
    const dispatch = useDispatch();
    const { unityProvider, isLoaded, loadingProgression, sendMessage, addEventListener, removeEventListener } = useUnityContext({
        loaderUrl: process.env.REACT_APP_UNITY_LOADER_URL,
        dataUrl: process.env.REACT_APP_UNITY_DATA_URL,
        frameworkUrl: process.env.REACT_APP_UNITY_FRAMEWORK_URL,
        codeUrl: process.env.REACT_APP_UNITY_CODE_URL,
    });

    const currentAvatarIndex = useSelector(
        (state) => state.avatar.roles[role].currentAvatarIndex
    );

    const activeAudio = useSelector(
        (state) => state.avatar.roles[role].activeAudio
    );

    useEffect(() => {
        sendMessage("CharacterSelect", "ChangeCharacter", AVATARS[currentAvatarIndex].video);
    }, [currentAvatarIndex]);

    useEffect(() => {
        if (activeAudio) {
            sendMessage('AudioPlayer', 'PlayAudio',
                JSON.stringify({
                    url: activeAudio.audioUrl,
                    duration: activeAudio.duration,
                    text: activeAudio.text }));
        }
    }, [activeAudio]);


    const updateAvatar = () => {
        dispatch(rotateAvatar({ role }));
    };

    return (
        <div className="avatar" onClick={updateAvatar}>
          {!isLoaded && (
            <div className="avatar__loading">
                <p>Loading... {Math.round(loadingProgression * 100)}%</p>
            </div>
          )}
          <Unity
              unityProvider={unityProvider}
              className="avatar__view"
              style={{
                  visibility: isLoaded ? "visible" : "hidden"
              }}
          />
        </div>
    );
};

export default Avatar;
