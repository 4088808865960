const logMessage = (level, message, data = null) => {
    console[level](`[${level.toUpperCase()}] ${message}`, ...(data ? [data] : []));
  };
  
  const loggerService = {
    info: (message, data = null) => logMessage('info', message, data),
    warn: (message, data = null) => logMessage('warn', message, data),
    error: (message, data = null) => logMessage('error', message, data),
    verbose: (message, data = null) => logMessage('debug', message, data),
  };
  
  export default loggerService;