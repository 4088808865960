import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ChatPanel from './chat/ChatPanel';
import { setSessionId } from '../redux/slices/sessionSlice';
import { fetchProgramInfo } from '../redux/slices/programSlice';
import { fetchCurrentLevel, selectCurrentLevelType } from '../redux/slices/currentLevelSlice';
import { sendMessage } from '../redux/slices/chatSlice';
import { handleLevelReset } from '../services/levelService';
import loggerService from '../services/loggerService';
import ActivityPanel from './activities/ActivityPanel';
import './AppContainer.scss';
import Header from './header/Header';
import { CSSTransition } from 'react-transition-group';
import AudioPlayer from './common/AudioPlayer';


const AppContainer = () => {
  const { sessionId } = useParams();
  const dispatch = useDispatch();
  const programData = useSelector((state) => state.program.data);


  const [isInitialized, setIsInitialized] = useState(false);

  const instructorRole = process.env.REACT_APP_AVATAR_ROLE_INSTRUCTOR;
  const currentLevelType = useSelector(selectCurrentLevelType);
  const isTTSEnabled = useRef(new URLSearchParams(window.location.search).get('tts') !== 'false');

  useEffect(() => {
    console.log("APPCONTAINER PANEL IS MOUNTED")
}, []);

  useEffect(() => {
    const initializeSession = async () => {
      loggerService.info('Session initialized', { sessionId });

      dispatch(setSessionId(sessionId));
      loggerService.verbose('Session ID set in Redux', { sessionId });

      try {
        // Fetch program info
        await dispatch(fetchProgramInfo(sessionId)).unwrap();
        loggerService.verbose('Program info fetched for session', { sessionId });

        // Reset level
        await handleLevelReset(sessionId);
        loggerService.verbose('Level reset for session', { sessionId });

        // Fetch current level
        await dispatch(fetchCurrentLevel(sessionId)).unwrap();
        loggerService.verbose('Current level fetched for session', { sessionId });

        setIsInitialized(true);
      } catch (error) {
        loggerService.error('Error during session initialization', { error: error.message });
        loggerService.verbose('Initialization error details', { error });
      }
    };

    initializeSession();
  }, [sessionId, dispatch]);

  useEffect(() => {
    if (isInitialized && programData && programData.program_flow_id) {
      const flowId = programData.program_flow_id;

      loggerService.info('Course started', { programTitle: programData.title });
      loggerService.verbose('Dispatching start message to flow', {
        flowId,
        sessionId,
      });
      loggerService.verbose('Start message content', { content: 'start_the_course' });
        dispatch(
          sendMessage({
            content: 'start_the_course',
            stream: true,
            sessionId,
            flowId,
            conversationType: instructorRole
          })
        );
    }
  }, [isInitialized, programData, sessionId, dispatch]);

  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    if (currentLevelType && currentLevelType !== 'theory' && !inProp) {
      setInProp(!inProp);
    }

    if (currentLevelType === 'theory' && inProp) {
      setInProp(!inProp);
    }
  }, [currentLevelType]);

  return (
    <div className="app-main">
      <Header title={programData ? programData.title : "Loading..."}></Header>

      <div className="app-main__container">
        <div className="app-main__chat">
          <ChatPanel />
        </div>

        <CSSTransition
          in={inProp}
          timeout={300}
          classNames="activity-slide"
          unmountOnExit
        >
          {/* Show ActivityPanel based on currentLevelType (either 'roleplay' or 'brainstorm') */}
          <div className="app-main__activity">
            <ActivityPanel
              activityType={currentLevelType}  // Pass the current level type to ActivityPanel
            />
          </div>
        </CSSTransition>
      </div>
      {isTTSEnabled.current && <AudioPlayer />}
    </div>
  );
};

export default AppContainer;
