import React from 'react';
import { useSelector } from 'react-redux';
import loggerService from '../../../services/loggerService';

import './Message.scss';

const Message = ({ messageId, isUser }) => {
  const message = useSelector((state) =>
    state.chat.messages.find((msg) => msg.id === messageId)
  );

  const content = message.chunks.map(chunk => chunk.content).join('');

  loggerService.verbose('Rendering message', { messageId, isUser, contentLength: content.length });

  return (
    <div className={`message ${isUser ? 'message--user' : 'message--assistant'}`}>
      {
        content && (
          <p>{content}</p>
        ) || <p className="message__typing-dots"></p>
      }
    </div>
  );
};

export default Message;
