import { createSlice } from '@reduxjs/toolkit';

// Access role variables from the environment
export const INSTRUCTOR = process.env.REACT_APP_AVATAR_ROLE_INSTRUCTOR;
export const BRAINSTORM_ASSISTANT = process.env.REACT_APP_AVATAR_ROLE_BRAINSTORM_ASSISTANT;
export const ROLEPLAY_PARTNER = process.env.REACT_APP_AVATAR_ROLE_ROLEPLAY_PARTNER;

// Define the constant avatars array outside of the state
export const AVATARS = [
  { voice: 'echo', video: 'mike' },
  { voice: 'onyx', video: 'stive' },
  { voice: 'nova', video: 'anna' },
];

// Initial state with roles defined by environment variables
const initialState = {
  roles: {
    [INSTRUCTOR]: {
      currentAvatarIndex: 0,
      activeAudio: null,
    },
    [BRAINSTORM_ASSISTANT]: {
      currentAvatarIndex: 0,
      activeAudio: null,
    },
    [ROLEPLAY_PARTNER]: {
      currentAvatarIndex: 0,
      activeAudio: null,
    },
  },
};

const avatarSlice = createSlice({
  name: 'avatar',
  initialState,
  reducers: {
    rotateAvatar(state, action) {
      const { role } = action.payload;
      const currentIndex = state.roles[role].currentAvatarIndex;
      const avatarsCount = AVATARS.length;

      // Rotate to the next avatar (cyclic rotation)
      state.roles[role].currentAvatarIndex = (currentIndex + 1) % avatarsCount;
    },
    setActiveAudio(state, action) {
      const { role, audioUrl, audioDuration, text } = action.payload;
      state.roles[role].activeAudio = { audioUrl, audioDuration, text };
    },
    clearActiveAudio(state, action) {
      const { role } = action.payload;
      state.roles[role].activeAudio = null;
    },
  },
});

// Export the actions
export const { rotateAvatar, setActiveAudio, clearActiveAudio } = avatarSlice.actions;

// Export the reducer to include in the store
export default avatarSlice.reducer;